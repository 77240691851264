<template>
  <v-app-bar
    app
    rounded
    :fade-img-on-scroll="appBarSettingFadeImageOnScroll"
    :shrink-on-scroll="appBarSettingShrinkOnScroll"
    :dark="dark"
    :color="appBarSettingColorClass"
    :collapse="collapse"
    :collapse-on-scroll="appBarSettingCollapseOnScroll"
    :src="appBarBackgroundImageSrc"
    :clipped-right="clippedRight"
    :prominent="appBarSettingProminent"
  >
    <!-- Customize Background Image -->
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        :gradient="appBarSettingGradient"
        v-if="appBarSettingGradient"
      ></v-img>
    </template>

    <!-- App Logo -->
    <slot name="logo">
      <v-toolbar-title>
        <v-avatar
          v-show="visibleAvatar"
          :size="avatarSize"
          :style="avatarStyle"
          tile
        >
          <v-img :src="avatarImgSrc" contain></v-img>
        </v-avatar>
        <span v-show="visibleTitle">{{ title }}</span>
      </v-toolbar-title>
    </slot>

    <v-spacer></v-spacer>

    <!-- User Menu -->
    <slot name="user">
      <!-- Popover Menu viewer-properties -->
      <popover-menu-viewer-properties
        v-model="visiblePopoverMenuEditor"
        @close="closePopoverMenuEditor"
      >
      </popover-menu-viewer-properties>
    </slot>

    <!-- Application Navigation -->
  </v-app-bar>
</template>

<script>
import { appBarMixin } from "@/mixins/shared/base/appBar/appBarMixin";

export default {
  name: "ExternalAppBar",
  mixins: [appBarMixin],
  components: {
    PopoverMenuViewerProperties: () =>
      import(
        "@/components/shared/core/menu/popover/PopoverMenuViewerProperties"
      )
  },
  data() {
    return {
      visiblePopoverMenuEditor: false
    };
  },

  methods: {
    /**
     * Event to close PopoverMenuEditor
     */
    closePopoverMenuEditor() {
      this.visiblePopoverMenuEditor = false;
    }
  }
};
</script>
